import { mapActions } from "vuex";

export default {
  methods: {
    ...mapActions({
      snackError: "snackbar/error",
      snackWarning: "snackbar/warning",
      snackInfo: "snackbar/info",
      snackSuccess: "snackbar/success",
      snackHide: "snackbar/hide",
    }),
  },
};
